import React, { createContext, useContext, Component } from 'react';
import { AccountService, UserType } from '@just-ai/aimychat-shared/dist/service/AccountService';
import OperatorPlaceService from '@just-ai/aimychat-shared/dist/service/OperatorPlaceService';
import {
  OperatorGroupDto,
  OperatorGroupFullDto,
  OperatorPlaceDto,
  OperatorWithGroupsDto,
  PrompterDto,
  ProductInfoDto,
} from '@just-ai/aimychat-shared/dist/api/client/aimychat';
import { AgentLimits, Locale, OptionsResponseDto } from '@just-ai/aimychat-shared/dist/api/client/accountadmin';
import GroupService from '@just-ai/aimychat-shared/dist/service/GroupService';
import OperatorService from '@just-ai/aimychat-shared/dist/service/OperatorService';
import { getUserLanguage } from './utils/pureFunctions';
import { axios, updateLanguage } from '@just-ai/aimychat-shared/dist/pipes/functions';
import localization from './localization';
import { Errors } from '@just-ai/aimychat-shared/dist/service/Errors';
import { getBackColorFromId, getColorFromId, getDargeBackColorFromId } from './modules/Settings/settingsHelpers';
import BillingService from '@just-ai/aimychat-shared/dist/service/BillingService';
import Cookies from 'universal-cookie';
import { getConfig } from '@just-ai/aimychat-shared/dist/service/ConfigService';
import PrompterApiService from '@just-ai/aimychat-shared/dist/service/PropmterApiService';
import { OptionsService } from '@just-ai/aimychat-shared/dist/service/OptionsService';
import ProductInfoService from '@just-ai/aimychat-shared/dist/service/ProductInfoService';
import { UserService } from '@just-ai/aimychat-shared/dist/service/UserService';
import OperatorPlaceIFrameService from '@just-ai/api/dist/services/OperatorPlaceIFrameService';
import { AppLogger } from '@just-ai/logger';
import { IFrameOperatorDto } from '@just-ai/api/dist/generated/Aimychat';

const cookies = new Cookies();

export type GroupsColor = Record<
  number,
  {
    backgroundColor: string;
    color: string;
    darkBackground: string;
  }
>;

type Account = { paymentSystem: string; owner: { email: string } };

class State {
  hasAccess = true;
  appLoading: boolean = true;
  accountLoading: boolean = false;
  id: number = 0;
  accountId: number | null = null;
  ccUserId: number = 0;
  operatorId: number = 0;
  isOnline?: boolean = undefined;
  isLoggedIn: boolean = false;
  email?: string = '';
  name?: string = '';
  accountShortName: string = '';
  language: string = '';
  role: string = '';
  isAdmin: boolean = false;
  groups: OperatorGroupFullDto[] = [];
  operators: OperatorWithGroupsDto[] = [];
  operatorPlace?: OperatorPlaceDto;
  operatorsFetching: boolean = false;
  groupsFetching: boolean = false;
  permissions: string[] = [];
  accountOwner = true;
  groupsColors = {};
  accountsList: AccountDataDto[] = [];
  operator: OperatorWithGroupsDto | null = null;
  config = {} as Awaited<ReturnType<typeof getConfig>>;
  agentLimits?: AgentLimits;
  options?: OptionsResponseDto;
  features = [] as string[];
  internal: boolean = false;
  prompters: PrompterDto[] = [];
  account: Account = {} as Account;
  productInfo: ProductInfoDto = {} as ProductInfoDto;
  isPrompterAccess = false;
  userId = 0;
  isOperatorInvitationAccess = false;
  isLts = false;
  isUnlimited = false;
  activeIframe: IFrameOperatorDto | null = null;
  isIframeEnabled: boolean = false;
  iframesOperatorPlace: IFrameOperatorDto[] = [];
  isSupervisorFeature = false;
}

export type AppContextType = State & {
  operatorPlaceService: OperatorPlaceService;
  prompterApiService: PrompterApiService;
  userService: UserService;
  OperatorPlaceIFrameService: OperatorPlaceIFrameService;

  logoutHandler: () => Promise<void>;
  setGroups: (callback: (groups: OperatorGroupFullDto[]) => OperatorGroupFullDto[]) => void;
  setOperators: (callback: (operators: OperatorWithGroupsDto[]) => OperatorWithGroupsDto[]) => void;
  setOperatorPlace: (operatorPlace: OperatorPlaceDto) => void;
  setOnlineStatusOperator: (status: boolean) => Promise<void>;
  getOperatorPlace: () => void;
  getGroups: () => void;
  getOperators: () => void;
  updateOnlineStatus: (isOnline: boolean) => void;
  changeAccount: (accountId: number) => void;
  getAgentLimits: () => void;
  setLanguage: (language: Locale) => void;
  getPrompters: () => void;
  setActiveIframe: (operator: IFrameOperatorDto | null) => void;
  fetchIframesOperatorPlace: () => void;
};

export type AccountDataDto = {
  id: number;
  name: string;
  default: boolean;
  owner: boolean;
};

export const AppContext = createContext({} as AppContextType);

export class AppContextProvider extends Component<{}, State> {
  state = new State();

  accountService: AccountService = new AccountService();
  operatorPlaceService: OperatorPlaceService = new OperatorPlaceService(0, 0);
  groupService: GroupService = new GroupService(0);
  operatorService: OperatorService = new OperatorService(0);
  billingService: BillingService = new BillingService(0);
  prompterApiService: PrompterApiService = new PrompterApiService(0);
  optionsService: OptionsService = new OptionsService();
  productInfoService: ProductInfoService = new ProductInfoService(AppLogger);
  userService: UserService = new UserService();
  OperatorPlaceIFrameService: OperatorPlaceIFrameService = new OperatorPlaceIFrameService(axios, 0);

  componentDidMount() {
    this.getCurrentAccountData();

    const getAgentLimits = async () => {
      const productInfo = await this.productInfoService.getProductInfo();
      if (productInfo) {
        this.setState({ productInfo });
      }
    };

    getAgentLimits();
  }

  getCurrentAccountData = async () => {
    this.setState({ accountLoading: true });
    let user = {} as UserType;
    let isAdmin = false;
    let isIframeEnabled = false;
    let role = '';
    let options;
    let allowAccounts = [];
    let account = {} as Account;
    let config = {} as Awaited<ReturnType<typeof getConfig>>;
    let operator = {} as OperatorWithGroupsDto;

    try {
      user = await this.accountService.authenticate();
      allowAccounts = await this.accountService.getAllowedAccounts(user.ccUserId);
      if (user.accountId) {
        account = await this.accountService.getAccount(user.accountId);
      }
      options = await this.optionsService.getOptions();
      config = await getConfig();

      const defaultAccount = allowAccounts.find((account: AccountDataDto) => account.default);
      if (!defaultAccount && !user.accountId && !user.internal) {
        return (window.location.href = '/c/select-project-group');
      }

      localization.setLocale(getUserLanguage(user.language));
      localization.setConfigVariables({ aimychat: { helpUrl: this.state.productInfo.helpUrl } });
      updateLanguage(user.language);
      this.operatorPlaceService = new OperatorPlaceService(user.id, user.ccUserId);
      this.groupService = new GroupService(user.id);
      this.operatorService = new OperatorService(user.id);
      this.billingService = new BillingService(user.id);
      this.prompterApiService = new PrompterApiService(user.id);
      this.OperatorPlaceIFrameService = new OperatorPlaceIFrameService(axios, user.accountId || 0);

      operator = await this.operatorPlaceService.getOwnOperator();

      role = operator.role;
      isIframeEnabled = await this.OperatorPlaceIFrameService.getOperatorIFrames(operator.id)
        .then(({ iframes }) => iframes.length > 0 && user.features.includes('aimychat_iframe'))
        .catch(error => {
          AppLogger.error({ message: error.message });
          return false;
        });
      isAdmin = role === 'ADMIN';

      const mainCalls = [this.getOperatorPlace, this.getOwnOperator, this.getAgentLimits];

      if (isAdmin || isIframeEnabled) {
        await Promise.all([...mainCalls.map(func => func()), this.getOperators(), this.getGroups()]);
        document.querySelector('#root')?.classList.remove('notAdminRole');
      } else {
        document.querySelector('#root')?.classList.add('notAdminRole');
        this.setGroupColors(operator.groups);
        await Promise.all(mainCalls.map(func => func()));
      }
    } catch (e) {
      if (e === Errors.GetOwnOperatorFail) {
        this.setState({ hasAccess: false });
      }
    }

    const paymentSystemIsUndifined = account.paymentSystem === 'UNDEFINED';

    this.setState({
      ...user,
      role,
      config,
      isAdmin,
      options,
      operator,
      account,
      appLoading: false,
      accountLoading: false,
      accountsList: allowAccounts,
      isLoggedIn: Boolean(this.operatorPlaceService.accountId),
      isPrompterAccess: user.features?.includes('prompter') && config.systemFeatures?.prompter_licensed,
      isOperatorInvitationAccess: user.features?.includes('operator-invitation'),
      isUnlimited: user.features?.includes('unlimited-agent') || paymentSystemIsUndifined,
      isIframeEnabled: user.features?.includes('aimychat_iframe'),
      isLts: paymentSystemIsUndifined,
      isSupervisorFeature: user.features?.includes('aimychat-supervisor'),
    });
  };

  setLanguage = (language: Locale) => this.setState({ language });

  getOperatorPlace = async () => {
    const operatorPlace = await this.operatorPlaceService.getOperatorPlace();
    this.setState({ operatorPlace });
  };

  getOwnOperator = async () => {
    const operator = await this.operatorPlaceService.getOwnOperator();
    this.setState({ operatorId: operator.id, isOnline: operator.isOnline });
  };

  getAgentLimits = async () => {
    const agentLimits = await this.billingService.getAgentLimits();
    this.setState({ agentLimits });
  };

  setOperatorPlace = (operatorPlace: OperatorPlaceDto) => this.setState({ operatorPlace });

  setOnlineStatusOperator = async (isOnline: boolean) => {
    this.setState({ isOnline });
  };

  setGroupColors = (groups: OperatorGroupDto[]) => {
    const groupsColors: GroupsColor = {};

    groups.forEach(
      (group, index) =>
        (groupsColors[group.id] = {
          backgroundColor: getBackColorFromId(index),
          color: getColorFromId(index),
          darkBackground: getDargeBackColorFromId(index),
        })
    );
    this.setState({ groupsColors });
  };

  getGroups = async () => {
    this.setState({ groupsFetching: true });
    const { groups } = await this.groupService.getGroups();

    this.setGroupColors(groups);

    this.setState({ groups, groupsFetching: false });
  };

  getOperators = async () => {
    this.setState({ operatorsFetching: true });
    const { operators } = await this.operatorService.getOperators();
    this.setState({ operators, operatorsFetching: false });
  };

  setAccountDataFromLogin = (accData: { id: number; name?: string; email?: string }) => {
    if (!accData) return;
    return this.setState({ ...accData, accountLoading: false });
  };

  logoutHandler = async () => {
    localStorage.removeItem('CURRENT_USER');
    window.location.href = `${window.location.origin}/c/logout`;
  };

  setGroups = (callback: (groups: OperatorGroupFullDto[]) => OperatorGroupFullDto[]) => {
    this.setState({ groups: callback(this.state.groups) });
  };

  setOperators = (callback: (operators: OperatorWithGroupsDto[]) => OperatorWithGroupsDto[]) => {
    this.setState({ operators: callback(this.state.operators) });
  };

  getPrompters = async () => {
    const { prompters } = await this.prompterApiService.getPrompters();
    this.setState({ prompters });
  };

  updateOnlineStatus = (isOnline: boolean) => this.setState({ isOnline });

  changeAccount = (accountId: number) => {
    cookies.set('SELECTED_ACCOUNT', String(accountId), { path: '/' });
    this.getCurrentAccountData();
  };

  setActiveIframe = (iframe: IFrameOperatorDto | null) => this.setState({ activeIframe: iframe });

  fetchIframesOperatorPlace = () => {
    const getInitials = (str: string): string => {
      const [firstWord = '', secondWord = ''] = str.split(' ');
      const firstInitial = firstWord[0] || '';
      const secondInitial = secondWord[0] || firstWord[1] || '';
      return `${firstInitial}${secondInitial}`.trim().toUpperCase();
    };

    this.OperatorPlaceIFrameService.getOperatorIFrames(this.state.operatorId)
      .then(({ iframes }) =>
        this.setState({
          iframesOperatorPlace: iframes.map(iframe => ({ ...iframe, name: getInitials(iframe.name) })),
        })
      )
      .catch(error => {
        AppLogger.error({ message: error.message });
      });
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          operatorPlaceService: this.operatorPlaceService,
          prompterApiService: this.prompterApiService,
          OperatorPlaceIFrameService: this.OperatorPlaceIFrameService,
          setGroups: this.setGroups,
          setOperators: this.setOperators,
          logoutHandler: this.logoutHandler,
          getOperatorPlace: this.getOperatorPlace,
          setOperatorPlace: this.setOperatorPlace,
          getGroups: this.getGroups,
          getOperators: this.getOperators,
          setOnlineStatusOperator: this.setOnlineStatusOperator,
          updateOnlineStatus: this.updateOnlineStatus,
          changeAccount: this.changeAccount,
          getAgentLimits: this.getAgentLimits,
          getPrompters: this.getPrompters,
          userService: this.userService,
          setLanguage: this.setLanguage,
          setActiveIframe: this.setActiveIframe,
          fetchIframesOperatorPlace: this.fetchIframesOperatorPlace,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export const useAppContext = () => useContext(AppContext);

export const withAppContext = Component => props => (
  <AppContext.Consumer>{contexts => <Component {...props} {...contexts} />}</AppContext.Consumer>
);
