import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useEffect, useRef } from 'react';
export const DynamicVirtualList = memo(({ onScroll, elements, bottomAutoScroll }) => {
    const containerRef = useRef(null);
    const handleScroll = (event) => {
        var _a;
        // @ts-ignore
        const scrollOffset = event ? event.target.scrollTop : (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().top;
        if (onScroll)
            onScroll(scrollOffset);
    };
    useEffect(() => {
        if (!containerRef || !bottomAutoScroll) {
            return;
        }
        const element = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current;
        setTimeout(() => element === null || element === void 0 ? void 0 : element.scrollTo({ top: element.scrollHeight }), 100);
    }, [bottomAutoScroll, containerRef]);
    return (_jsx("div", { "data-test-id": 'DynamicVirtualList.Container', className: 'ClientDialog_MessagesContainer', style: { position: 'relative', width: '100%', height: '100%', overflow: 'auto', paddingBottom: 12 }, ref: containerRef, onScroll: handleScroll, children: elements.map(element => (_jsx("div", { children: element.elem({ id: element.id, element: element.elem }) }, element.id))) }));
});
