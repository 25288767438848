import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Spinner } from '../../Spinner';
import { isSelected, shouldShowOptionsList } from '../utils';
import { MessagesTypes } from '../types';
import 'react-perfect-scrollbar/dist/css/styles.css';
export const JustSelectList = React.forwardRef(({ submitTimeout, listStyles, selfStyles, maxSelected, selected, getScrollBar, innerOptions, options, searchValue, onSelectHandler, onHoverHandler, ComponentSelectItem, focusedElement, messages, opened, dropdownPosition, position, classNameForList, size, dataTestId, isLoading, blockHtml, }, ref) => {
    const documentBodyOverflow = useRef(document.body.style.overflow || 'auto');
    useEffect(() => {
        if (opened && position === 'fixed')
            document.body.style.overflow = 'hidden';
    }, [opened, position]);
    useEffect(() => {
        const documentBodyOverflowValue = documentBodyOverflow.current;
        return () => {
            document.body.style.overflow = documentBodyOverflowValue;
        };
    }, [position]);
    return (_jsx("div", { className: classNames(`just-select-list ${dropdownPosition}`, { xs: size === 'xs' }, { disabled: maxSelected === selected.length, opened: opened }, { fixed: position === 'fixed' }, classNameForList), "data-test-id": `${dataTestId ? dataTestId + '_' : ''}just-select-list`, style: Object.assign(Object.assign({}, listStyles), selfStyles), tabIndex: 1, ref: ref, children: _jsx(SelectListChildren, { ComponentSelectItem: ComponentSelectItem, getScrollBar: getScrollBar, submitTimeout: submitTimeout, innerOptions: innerOptions, options: options, selected: selected, searchValue: searchValue, onSelectHandler: onSelectHandler, focusedElement: focusedElement, onHoverHandler: onHoverHandler, messages: messages, isLoading: isLoading, blockHtml: blockHtml }) }));
});
const SelectListChildren = ({ ComponentSelectItem, getScrollBar, submitTimeout, innerOptions, options, selected, searchValue, onSelectHandler, focusedElement, onHoverHandler, messages, isLoading, dataTestId, blockHtml, }) => (_jsx("div", { className: 'relativeWrapper', children: _jsxs(PerfectScrollbar, { options: { maxScrollbarLength: 55 }, containerRef: getScrollBar, children: [submitTimeout && !submitTimeout.cleared && !submitTimeout.timeouted && !isLoading && options.length > 0 && (_jsx(ComponentSelectItem, { value: '', label: _jsx(Spinner, { inline: true }) }, 'options$Loading')), isLoading && _jsx(ComponentSelectItem, { value: '', label: _jsx(Spinner, { inline: true }) }, 'options$Loading'), shouldShowOptionsList(innerOptions, options, selected, true, searchValue) &&
                !isLoading &&
                innerOptions.map((option, index) => (_jsx(ComponentSelectItem, Object.assign({ onSelect: onSelectHandler, active: isSelected(selected, option), focused: focusedElement === index, onHover: onHoverHandler(index), blockHtml: blockHtml }, option), `options${option.value}`))), options.length > 0 &&
                submitTimeout &&
                !submitTimeout.cleared &&
                submitTimeout.timeouted &&
                !shouldShowOptionsList(innerOptions, options, selected, true, searchValue) &&
                !isLoading && (_jsx(ComponentSelectItem, { value: messages[MessagesTypes.NOT_FOUND], label: messages[MessagesTypes.NOT_FOUND] }, 'options$Nothing found')), !isLoading && ((options === null || options === void 0 ? void 0 : options.length) === 0 || (options === null || options === void 0 ? void 0 : options.length) - (selected === null || selected === void 0 ? void 0 : selected.length) === 0) && (_jsx(ComponentSelectItem, { value: messages[MessagesTypes.EMPTY], label: messages[MessagesTypes.EMPTY] }, 'options$Empty'))] }) }));
