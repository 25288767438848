import React, { useState, useMemo, useCallback, memo } from 'react';
import { Modal, InputText, useToggle, JustSelect, JustSelectMultiple } from '@just-ai/just-ui';
import { t } from '../../../localization';
import OperatorService from '@just-ai/aimychat-shared/dist/service/OperatorService';
import {
  OperatorWithGroupsDto,
  OperatorRole,
  OperatorGroupFullDto,
} from '@just-ai/aimychat-shared/dist/api/client/aimychat';
import { useAppContext } from '../../../AppContext';
import classNames from 'classnames';

type Props = {
  onClose: () => void;
  addOperator: (operator: OperatorWithGroupsDto) => void;
  editOperator: (operator: OperatorWithGroupsDto) => void;
  operatorToEdit?: OperatorWithGroupsDto;
  filteredOperators: OperatorWithGroupsDto[];
};

const CreateOperatorModal = memo(({ onClose, addOperator, editOperator, operatorToEdit, filteredOperators }: Props) => {
  const { id, groups, groupsColors, operators, options, productInfo, isSupervisorFeature } = useAppContext();
  const [loginError, setLoginError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [login, setLogin] = useState(operatorToEdit?.login || '');
  const [role, setRole] = useState<OperatorRole>(operatorToEdit?.role || OperatorRole.AGENT);
  const [operatorGroupIds, setChoosenGroups] = useState<number[]>(operatorToEdit?.groups?.map(({ id }) => id) || []);
  const [mainOperatorGroupId, setMainOperatorGroupId] = useState<number | undefined>(
    groups.find(group => group.mainOperator?.id === operatorToEdit?.id)?.id || -1
  );
  const [inCreating, setInCreating, finishCreating] = useToggle(false);
  const userAlreadySelected = useMemo(
    () =>
      !!filteredOperators.some(({ email, login: operatorsLogin }) => login === email || operatorsLogin === login)
        ? t('OperatorPlace: user already been added')
        : '',
    [filteredOperators, login]
  );

  const justSelectRef = useCallback(
    node => {
      if (node) {
        setTimeout(() => {
          const groupItems = document.querySelectorAll<HTMLElement>('.just-select-selected-item');

          if (groupItems) {
            groupItems.forEach((item, index) => {
              const id = groups.find(({ name }) => name === item.innerText)?.id || index;
              item.style.backgroundColor = groupsColors[id]?.backgroundColor;
              item.style.color = groupsColors[id]?.color;
            });
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groups, operatorGroupIds]
  );
  const service = useMemo(() => new OperatorService(id), [id]);
  const handleChangeLogin = useCallback(
    (value: string) => {
      setLogin(value);
      if (loginError) {
        setLoginError('');
      }
    },
    [loginError]
  );

  const handleChangeRole = useCallback(
    role => {
      if (!role) {
        return;
      }
      const currentRole = (typeof role === 'string' ? role : role[0]) as OperatorRole;
      if (
        currentRole === OperatorRole.AGENT &&
        operatorToEdit?.role === OperatorRole.ADMIN &&
        operators.filter(({ role }) => role === OperatorRole.ADMIN).length === 1
      ) {
        setRoleError(t('Settings:Users:OneAdminRequired'));
        return;
      }
      setRoleError('');
      setRole(currentRole);
    },
    [operatorToEdit?.role, operators]
  );

  const onConfirm = useCallback(async () => {
    const errorText = t('OperatorPlace: user does not exist');

    if (!login) {
      setLoginError(t('RequiredField'));
      return;
    }

    try {
      setInCreating();
      const currentRole = Array.isArray(role) ? role[0] : (role as OperatorRole);
      if (operatorToEdit) {
        const operator = await service.updateOperator(operatorToEdit.id, {
          role: currentRole,
          operatorGroupIds,
          mainOperatorGroupId,
        });
        editOperator(operator);
      } else {
        const { result, operator } = await service.addOperator({ login, role: currentRole });
        if (!result || !operator) {
          throw errorText;
        }
        operator && addOperator(operator);
      }
      finishCreating();
      onClose();
    } catch (e) {
      setLoginError(errorText);
      finishCreating();
    }
  }, [
    login,
    setInCreating,
    role,
    operatorToEdit,
    finishCreating,
    onClose,
    service,
    operatorGroupIds,
    mainOperatorGroupId,
    editOperator,
    addOperator,
  ]);

  const optionsMainOperator = useMemo(() => {
    const groupsWithoutMainOperator = groups.filter(group => {
      return !group.mainOperator || group.mainOperator.id === operatorToEdit?.id;
    });

    const noGroup: OperatorGroupFullDto = {
      id: -1,
      name: t('Settings:Operator:MainOperatorForGroup:NoGroup'),
    } as OperatorGroupFullDto;
    return [noGroup, ...groupsWithoutMainOperator].map(group => ({ value: group.id, label: group.name }));
  }, [groups, operatorToEdit?.id]);

  const handleChangeMainOperatorGroupId = useCallback(value => {
    const id = Number(value.join(''));
    setMainOperatorGroupId(id === -1 ? undefined : id);
  }, []);

  return (
    <Modal
      onClickCloseIcon={inCreating ? undefined : onClose}
      onCancelClick={onClose}
      buttonCancelColor='secondary'
      onActionClick={onConfirm}
      title={t(operatorToEdit ? 'Settings:Operator edit modal title' : 'Settings:Operator create modal title')}
      buttonSubmitText={t(operatorToEdit ? 'Save' : 'Settings:Operator send request button')}
      buttonSubmitDisabled={
        (!operatorToEdit && (!!userAlreadySelected || !!loginError || !login)) || (operatorToEdit && !!roleError)
      }
      buttonCancelText={t('Cancel')}
      buttonCancelTestId='Operators.CreateModal.cancel'
      buttonSubmitTestId='Operators.CreateModal.submit'
      className='mobile-modal CreateOperatorModal_container'
      inProgress={inCreating}
      disableActionButtonAutoFocus
      centered
      isOpen
    >
      {operatorToEdit ? (
        <div className='flex-column margin-bottom-6x'>
          <span className='font-color-bright'>
            {operatorToEdit.firstName || ''} {operatorToEdit.lastName || ''}
          </span>
          <span className='font-color-light-gray font-size-12'>{operatorToEdit.login}</span>
        </div>
      ) : (
        <>
          <p>
            <small
              dangerouslySetInnerHTML={{
                __html: t(
                  'Settings:Operator:Create:Description',
                  productInfo.productName,
                  `https://${options?.domains.cc.domain}/c/accounts`
                ),
              }}
            />
          </p>
          <p>
            <small
              dangerouslySetInnerHTML={{
                __html: t('Settings:Operator:Create:Description:Link'),
              }}
            />
          </p>
        </>
      )}
      <fieldset className='margin-bottom-6x'>
        <label>{t('Settings:Operator:Operator:Role:label')}</label>
        <span className='ErrorMessage'>*</span>
        <JustSelect
          ref={justSelectRef}
          className={classNames('gray-select', { DangerBorder: roleError })}
          defaultValue={role}
          position='fixed'
          data-test-id='Operators.CreateModal.role'
          fullWidth
          options={Object.values(OperatorRole)
            .filter(role => isSupervisorFeature || (!isSupervisorFeature && role !== OperatorRole.SUPERVISOR))
            .map(role => ({
              value: role,
              label: t(`Settings:Operator:Operator:Role:${role}`),
            }))}
          onChange={handleChangeRole}
        />
        {roleError && (
          <p className='ErrorMessage'>
            <small>{roleError}</small>
          </p>
        )}
      </fieldset>
      {operatorToEdit ? (
        <fieldset className={classNames({ 'margin-bottom-6x': operatorToEdit })}>
          <label>{t('Settings:Operator operator group label')}</label>
          <JustSelectMultiple
            disabled={groups.length === 0}
            options={groups?.map(group => ({ value: group.id, label: group.name }))}
            className='gray-select'
            position='fixed'
            data-test-id='Operators.CreateModal.groups'
            fullWidth
            value={operatorGroupIds}
            onChange={groups => setChoosenGroups((groups || []) as number[])}
          />
        </fieldset>
      ) : (
        <fieldset className={classNames({ 'margin-bottom-6x': operatorToEdit })}>
          <label>{t('Settings:Operator operator login label')}</label>
          <InputText
            blurInactive
            data-test-id='Operators.CreateModal.login'
            defaultValue={login}
            onChange={handleChangeLogin}
            errorText={userAlreadySelected || loginError}
          />
        </fieldset>
      )}
      {operatorToEdit && (
        <fieldset>
          <label>{t('Settings:Operator:MainOperatorForGroup')}</label>
          <JustSelect
            defaultValue={mainOperatorGroupId}
            disabled={groups.length === 0}
            options={optionsMainOperator}
            className='gray-select'
            position='fixed'
            data-test-id='Operators.CreateModal.mainOperatop'
            fullWidth
            onChange={handleChangeMainOperatorGroupId}
          />
          <span className='font-size-12 font-color-light-gray'>
            {t('Settings:Operator:MainOperatorForGroup:Description')}
          </span>
        </fieldset>
      )}
    </Modal>
  );
});

export default CreateOperatorModal;
