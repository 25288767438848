import { useCallback, useEffect, useRef, useState } from 'react';
import { IFrameOperatorDto } from '@just-ai/api/dist/generated/Aimychat';
import { useAppContext } from '../../AppContext';

import './styles.scss';

const compiledCSS = `
  .justwidget--asst { display: none !important; }
  body { padding: 0; margin: 0; }
  #widget-root > .justwidget.mobile_right { left: 0 !important; }
  .justwidget { width: 100% !important; }
  .justwidget--message { width: auto; margin: 0 16px; }
`;

type scriptTypeConfigsType = {
  code: string;
  isApplicable: (script: string) => boolean;
  beforeUnload: (IFrameElement: HTMLIFrameElement) => void;
  beforeLoad: (IFrameElement: HTMLIFrameElement) => void;
  afterLoad: (IFrameElement: HTMLIFrameElement, onClose: () => void) => void;
};

const scriptTypeConfigs: scriptTypeConfigsType[] = [
  {
    code: 'JustWidget',
    isApplicable: htmlCode => {
      const substring1 = '/chatwidget/';
      const substring2 = '/justwidget.js';

      return htmlCode.includes(substring1) && htmlCode.includes(substring2);
    },
    beforeUnload: IFrameElement => {},
    beforeLoad: IFrameElement => {
      const { contentDocument, contentWindow } = IFrameElement;
      const styleIframeElement = contentDocument?.createElement('style');

      if (styleIframeElement) {
        styleIframeElement.appendChild(document.createTextNode(compiledCSS));
        contentDocument?.head.appendChild(styleIframeElement);
      }

      contentDocument?.addEventListener('justwidget_ready', () => contentWindow?.JustWidget?.toggleWidget());
    },
    afterLoad: (IFrameElement, onClose) => {
      const { contentDocument } = IFrameElement;

      const destroyWidgetWhenHidden = () => {
        const widget = contentDocument?.querySelector('.justwidget_hidden');
        if (widget) onClose();
      };

      contentDocument?.addEventListener('justwidget_toggle', destroyWidgetWhenHidden);
    },
  },
];

const useStarterIframeOperatorPlace = () => {
  const [isLoading, setIsLoading] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const scriptTypeConfigRef = useRef<scriptTypeConfigsType | null>(null);
  const { setActiveIframe, activeIframe, accountId } = useAppContext();

  const onIFrameUnload = useCallback(() => {
    document.getElementById('OperatorWidgetIframeId')?.remove();
    setActiveIframe(null);
    scriptTypeConfigRef.current = null;
    iframeRef.current = null;
  }, [setActiveIframe]);

  const onIFrameLoad = useCallback(() => {
    setIsLoading(false);
    if (scriptTypeConfigRef.current && iframeRef.current) {
      scriptTypeConfigRef.current?.afterLoad(iframeRef.current, onIFrameUnload);
    }
  }, [onIFrameUnload]);

  const loadAndRunScript = useCallback(
    (iframe: IFrameOperatorDto) => {
      onIFrameUnload();
      setIsLoading(true);
      if (iframe.id === activeIframe?.id) {
        setActiveIframe(null);
        return;
      }

      setActiveIframe(iframe);

      if (iframe.htmlCode) {
        scriptTypeConfigRef.current =
          scriptTypeConfigs.find(({ isApplicable }) => iframe.htmlCode && isApplicable(iframe.htmlCode)) || null;

        if (scriptTypeConfigRef.current?.code !== 'JustWidget') return;

        const iframeElement = document.createElement('iframe');
        iframeRef.current = iframeElement;

        iframeElement.id = 'OperatorWidgetIframeId';
        iframeElement.style.zIndex = '777';

        iframeElement.style.width = `${iframe.config.width}px`;
        iframeElement.style.height = `${iframe.config.height}px`;

        iframeElement.addEventListener('load', onIFrameLoad);

        document.body.appendChild(iframeElement);

        iframeElement.contentWindow?.document.open();
        iframeElement.contentWindow?.document.write(iframe.htmlCode);
        iframeElement.contentWindow?.document.close();

        scriptTypeConfigRef.current.beforeLoad(iframeElement);
      }
    },
    [activeIframe?.id, onIFrameLoad, onIFrameUnload, setActiveIframe]
  );

  useEffect(() => () => onIFrameUnload(), [accountId]);

  return { loadAndRunScript, isLoading };
};

export default useStarterIframeOperatorPlace;
