import React, { memo, useCallback, useState } from 'react';
import './ChatHeader.scss';
import { getUserFullName } from '../operatorPlaceHelpers';
import { t } from '../../../localization';
import { useOperatorDataContext } from '../OperatorDataContext';
import { ChatHeaderButtons } from './ChatHeaderButtons';
import { useIsMobile } from '@just-ai/aimychat-shared/dist/utils/hooks';
import { IconButton } from '@just-ai/just-ui';
import { OperatorChatStatus } from '@just-ai/aimychat-shared/dist/api/client/operator';
import { AboutProps } from '../Chat';
import { ChatHeaderMobile } from './ChatHeaderMobile';

export const ChatHeader = memo((props: AboutProps) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { chosenChat: chat } = useOperatorDataContext();
  const isMobile = useIsMobile();

  const isBlocked = !!chat?.blockingInfo?.isBlocked;
  const fullName = (chat && getUserFullName(chat.clientInfo)) || chat?.clientInfo?.id || '';
  const channelType = chat?.context?.channelType;

  const toggleMobileMenu = useCallback(() => setShowMobileMenu(!showMobileMenu), [showMobileMenu]);

  if (isMobile) {
    const canOpenMenu = chat && chat.status !== OperatorChatStatus.CREATED;

    return (
      <ChatHeaderMobile
        elements={{
          Middle: () => (
            <>
              <div className='ChatHeader_container__mobile__name'>{fullName}</div>
              <div className='ChatHeader_container__mobile__channel'>
                {channelType && t(`ChannelType ${channelType.toUpperCase()}`)}
              </div>
            </>
          ),
          Right: () =>
            canOpenMenu ? (
              <>
                <IconButton name='farEllipsisV' onClick={toggleMobileMenu} flat />
                {showMobileMenu && (
                  <div className='ChatHeader_container__mobile__menu'>
                    <ChatHeaderButtons setShowMobileMenu={setShowMobileMenu} {...props} />
                  </div>
                )}
              </>
            ) : null,
        }}
      />
    );
  }

  return (
    <div className='ChatHeader_container'>
      <div data-test-id='Chat.Header.Info' className='ChatHeader_info'>
        <div data-test-id='Chat.Header.Info.name' data-test-name={`Chat.Header.Info.name.${fullName}`}>
          {fullName}
        </div>
        <div
          data-test-block={`Chat.Header.Info.blockStatus.${isBlocked ? 'blocked' : 'unblocked'}`}
          className='flex-row gap-4'
        >
          <small
            data-test-id='Chat.Header.Info.channelType'
            data-test-channeltype={`Chat.Header.Info.channelType.${channelType}`}
            className='font-color-light-gray margin-right-2x nowrap'
          >
            {channelType && t(`ChannelType ${channelType.toUpperCase()}`)}
          </small>{' '}
          {isBlocked && (
            <span data-test-id='Chat.Header.Info.blockStatus' className='font-color-danger'>
              {t('OperatorPlace: header blocked status')}
            </span>
          )}
        </div>
      </div>
      <ChatHeaderButtons />
    </div>
  );
});
