import React, { useState, useMemo, useCallback, memo, useRef, useEffect } from 'react';
import { Modal, Tabs, TabValue, useToggle } from '@just-ai/just-ui';
import { t } from '../../../../../localization';
import GroupService from '@just-ai/aimychat-shared/dist/service/GroupService';
import {
  ContextPrompterSettingsDto,
  OperatorGroupFullDto,
  PrompterDto,
} from '@just-ai/aimychat-shared/dist/api/client/aimychat';
import { useAppContext } from '../../../../../AppContext';
import { MainTab } from './Tabs/MainTab';
import { UsersTab } from './Tabs/UsersTab';
import { DEFAULT_MAX_RESPONSES, PromptersTab } from './Tabs/PromptersTab';
import './Modal.scss';
import { useOperatorDataContext } from '../../../../OperatorPlace/OperatorDataContext';
import { FramesTab } from './Tabs/FramesTab';
import { UpdateOperatorGroupIFrameDto } from '@just-ai/api/dist/generated/Aimychat';
import { SupervisorsTab } from './Tabs/SupervisorsTab';

export type PrompterInfo = {
  prompters: PrompterDto[];
  contextPrompterId?: number;
  contextPrompterSettings?: ContextPrompterSettingsDto;
};

const CreateGroupModal = memo(
  ({ onClose, groupToEdit }: { onClose: () => void; groupToEdit?: OperatorGroupFullDto }) => {
    const {
      id,
      setGroups,
      getOperators,
      getGroups,
      getPrompters,
      isPrompterAccess,
      prompters,
      OperatorPlaceIFrameService,
      isIframeEnabled,
      fetchIframesOperatorPlace,
      isSupervisorFeature,
    } = useAppContext();
    const { getOperatorGroups } = useOperatorDataContext();
    const [name, setName] = useState(groupToEdit?.name || '');
    const [mainOperatorId, setMainOperatorId] = useState<number | undefined>(groupToEdit?.mainOperator?.id);
    const [wasFilled, setWasFilled] = useState(false);
    const [iFrames, setIframes] = useState<UpdateOperatorGroupIFrameDto[]>([]);
    const errorText = useMemo(() => (!name && wasFilled ? t('RequiredField') : undefined), [name, wasFilled]);
    const [prompterInfo, setPrompterInfo] = useState<PrompterInfo>({
      prompters: groupToEdit?.prompters || [],
      contextPrompterId: groupToEdit?.contextPrompterId || 0,
      contextPrompterSettings: groupToEdit?.contextPrompterSettings,
    });

    const [choosenOperators, setChoosenOperators] = useState(groupToEdit?.operators.map(({ id }) => id) || []);
    const [selectedSupervisors, setSelectedSupervisors] = useState(groupToEdit?.supervisors || []);

    const tabs: TabValue[] = [
      { name: t('Settings:Prompters:Modal:Tabs:Main'), value: 'main', dataTestId: 'groups-tabs-main' },
      { name: t('Settings:Prompters:Modal:Tabs:Users'), value: 'users', dataTestId: 'groups-tabs-users' },
      { name: t('Settings:Prompters:Modal:Tabs:Prompters'), value: 'prompters', dataTestId: 'groups-tabs-prompters' },
      { name: t('Settings:Prompters:Modal:Tabs:Frames'), value: 'frames', dataTestId: 'groups-tabs-frames' },
      {
        name: t('Settings:Prompters:Modal:Tabs:Supervisors'),
        value: 'supervisors',
        dataTestId: 'groups-tabs-supervisors',
      },
    ]
      .filter(
        ({ value }) =>
          (value === 'prompters' && isPrompterAccess) ||
          (value !== 'prompters' && (isIframeEnabled || (!isIframeEnabled && value !== 'frames')))
      )
      .filter(({ value }) => (value === 'supervisors' && isSupervisorFeature) || value !== 'supervisors');

    const [activeTab, setActiveTab] = useState('main');

    const unmounted = useRef(false);

    const [inCreating, setInCreating, finishCreating] = useToggle(false);

    const service = useMemo(() => new GroupService(id), [id]);

    const addGroup = useCallback(
      (group: OperatorGroupFullDto) => {
        setGroups(groups => [...groups, group]);
        getOperators();
        getGroups();
      },
      [setGroups, getOperators, getGroups]
    );

    const editGroup = useCallback(
      (editedGroup: OperatorGroupFullDto) => {
        setGroups(groups => groups.map(group => (group.id === editedGroup.id ? editedGroup : group)));
        getOperators();
      },
      [setGroups, getOperators]
    );

    const onConfirm = useCallback(async () => {
      if (!name) {
        return;
      }
      const prompters = prompterInfo.prompters.map(({ id }) => id);
      const isResponseByOperatorRequest = prompterInfo.contextPrompterSettings?.isResponseByOperatorRequest;
      const maxResponseCount = prompterInfo.contextPrompterSettings?.maxResponseCount;
      const contextPrompterId = prompterInfo.contextPrompterId || undefined;

      const payload = {
        name,
        operators: choosenOperators,
        supervisors: selectedSupervisors.map(({ id }) => id),
        mainOperatorId: choosenOperators.includes(mainOperatorId || 0) ? mainOperatorId : undefined,
        prompters,
        contextPrompterId,
        contextPrompterSettings: {
          isResponseByOperatorRequest: contextPrompterId ? isResponseByOperatorRequest : undefined,
          maxResponseCount: contextPrompterId ? maxResponseCount || DEFAULT_MAX_RESPONSES : undefined,
        },
      };

      try {
        let group;
        setInCreating();
        if (groupToEdit?.id) {
          group = await service.updateGroup(groupToEdit?.id, payload);
          editGroup(group);
        } else {
          if (!unmounted.current) {
            group = await service.createGroup(payload);
            addGroup(group);
          }
        }
        if (group?.id) {
          OperatorPlaceIFrameService.updateOperatorGroupIFrame(group.id, { iframes: iFrames }).finally(() =>
            fetchIframesOperatorPlace()
          );
        }
        finishCreating();

        onClose();
      } catch (e) {
        console.error(e);
      }
      getOperatorGroups();
    }, [
      name,
      prompterInfo.prompters,
      prompterInfo.contextPrompterSettings?.isResponseByOperatorRequest,
      prompterInfo.contextPrompterSettings?.maxResponseCount,
      prompterInfo.contextPrompterId,
      choosenOperators,
      selectedSupervisors,
      mainOperatorId,
      getOperatorGroups,
      setInCreating,
      groupToEdit?.id,
      finishCreating,
      onClose,
      service,
      editGroup,
      addGroup,
      OperatorPlaceIFrameService,
      fetchIframesOperatorPlace,
      iFrames,
    ]);

    useEffect(() => {
      return () => {
        unmounted.current = true;
      };
    }, []);

    useEffect(() => {
      getPrompters();
    }, [getPrompters]);

    return (
      <Modal
        onClickCloseIcon={inCreating ? undefined : onClose}
        onCancelClick={onClose}
        buttonCancelColor='secondary'
        onActionClick={onConfirm}
        title={t(groupToEdit ? 'Settings:Group edit modal title' : 'Settings:Group create modal title')}
        buttonSubmitText={t(groupToEdit ? 'Save' : 'Add')}
        buttonCancelText={t('Cancel')}
        buttonCancelTestId='Groups.CreateModal.cancel'
        buttonSubmitTestId='Groups.CreateModal.submit'
        buttonSubmitDisabled={!name.trim() || errorText}
        className='mobile-modal full-height-modal mobile-no-margin high-height-modal'
        disableActionButtonAutoFocus
        isOpen
        centered
        inProgress={inCreating}
      >
        <Tabs tabs={tabs} activeTab={activeTab} onChange={setActiveTab} />
        {activeTab === 'main' && (
          <MainTab
            name={name}
            setName={setName}
            mainOperatorId={mainOperatorId}
            setMainOperatorId={setMainOperatorId}
            groupToEdit={groupToEdit}
            errorText={errorText}
            wasFilled={wasFilled}
            setWasFilled={setWasFilled}
          />
        )}
        {activeTab === 'users' && (
          <UsersTab choosenOperators={choosenOperators} setChoosenOperators={setChoosenOperators} />
        )}
        {isPrompterAccess && activeTab === 'prompters' && (
          <PromptersTab prompters={prompters} prompterInfo={prompterInfo} setPrompterInfo={setPrompterInfo} />
        )}
        {activeTab === 'frames' && <FramesTab group={groupToEdit} setIframes={setIframes} />}
        {activeTab === 'supervisors' && (
          <SupervisorsTab selectedSupervisors={selectedSupervisors} setSelectedSupervisors={setSelectedSupervisors} />
        )}
      </Modal>
    );
  }
);

export default CreateGroupModal;
