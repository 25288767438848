import { InputGroup, InputText } from '@just-ai/just-ui';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useAppContext } from '../../../../../../AppContext';
import { t } from '../../../../../../localization';
import { filterOperator } from '../../../../settingsHelpers';
import { OperatorInfo } from '../../../OperatorInfo';
import { OperatorDto, OperatorRole } from '@just-ai/aimychat-shared/dist/api/client/aimychat';

type Props = {
  selectedSupervisors: OperatorDto[];
  setSelectedSupervisors: Dispatch<SetStateAction<OperatorDto[]>>;
};

export const SupervisorsTab = ({ setSelectedSupervisors, selectedSupervisors }: Props) => {
  const { operators } = useAppContext();
  const [filter, setFilter] = useState('');

  const filteredSupervisors = operators.filter(
    operator =>
      operator.role === OperatorRole.SUPERVISOR &&
      filterOperator(
        filter,
        operator,
        selectedSupervisors.map(({ id }) => id),
        true
      )
  );

  const setOperators = useCallback(
    (id: number) => {
      setSelectedSupervisors(supervisors => {
        const hasSupervisor = supervisors.some(({ id: operatorId }) => operatorId === id);

        if (hasSupervisor) {
          return supervisors.filter(({ id: operatorId }) => operatorId !== id);
        }

        const supervisor = operators.find(({ id: operatorId }) => operatorId === id);

        return supervisor ? [...supervisors, supervisor] : supervisors;
      });
    },
    [setSelectedSupervisors, operators]
  );

  return (
    <fieldset className='margin-bottom-6x'>
      <InputGroup PrependInner={[{ name: 'farSearch' }]}>
        <InputText
          onChange={setFilter}
          data-test-id='Groups.CreateModal.filter'
          blurInactive
          placeholder={t('Settings:Group:Supervisors:Search:Placeholder')}
        />
      </InputGroup>
      <div className='font-size-12 margin-top-3x margin-bottom-3x'>
        {t('Settings:Group:Supervisors:Founded')} {filteredSupervisors.length}.{' '}
        {t('Settings:Groups:Create:Group:Selected:Supervisors')} {selectedSupervisors.length}
      </div>
      <div
        className='flex-column'
        style={{
          maxHeight: '10rem',
          overflow: 'auto',
          gap: 4,
          marginBottom: 8,
        }}
      >
        {filteredSupervisors.map(operator => (
          <div key={operator.id} className='OperatorInfo_container'>
            <OperatorInfo
              operator={operator}
              onClick={setOperators}
              choosen={selectedSupervisors.some(({ id }) => id === operator.id)}
            />
          </div>
        ))}
      </div>
    </fieldset>
  );
};
