import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Icon } from '../../Icon';
import { getSelectedItem } from '../SelectedItem';
export const JustSelectContainer = ({ focused, onFocus, selected, inputPlaceholder, searchValue, onChange, selectedItemComponent, selectedItemComponentProps, backSpaceDeleteSelected, deleteSelectedItem, cleanupSelected, autoFocus, iconArrowShow = true, invalid, disabled, size = 'md', dropdownIconName = 'faJustSelect', hideMultipleSelectedOptions, dataTestId, closeable, onValueClick, blockHtml, }) => {
    const SelectedItemComponent = getSelectedItem(selectedItemComponent, selectedItemComponentProps);
    const inputElement = useRef(null);
    const deleteSelection = useCallback(selectedItem => () => {
        deleteSelectedItem(selectedItem);
    }, [deleteSelectedItem]);
    const handleClickArrow = (evt) => {
        const previousNode = evt.currentTarget.previousSibling;
        if ((previousNode === null || previousNode === void 0 ? void 0 : previousNode.nodeName) === 'DIV') {
            const justSelect = previousNode.previousSibling;
            justSelect === null || justSelect === void 0 ? void 0 : justSelect.focus();
        }
        if ((previousNode === null || previousNode === void 0 ? void 0 : previousNode.nodeName) === 'SPAN') {
            previousNode === null || previousNode === void 0 ? void 0 : previousNode.focus();
        }
        if ((previousNode === null || previousNode === void 0 ? void 0 : previousNode.nodeName) === 'INPUT') {
            previousNode === null || previousNode === void 0 ? void 0 : previousNode.focus();
        }
    };
    const styleIconCleanUp = iconArrowShow ? { right: '2rem' } : {};
    useEffect(() => {
        var _a;
        if (autoFocus) {
            (_a = inputElement.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, []);
    return (_jsxs("div", { className: classNames(`just-select-container multiple ${invalid ? 'error' : ''} ${size}`, { focused: focused }, { closeable }, { disabled: disabled }), children: [!hideMultipleSelectedOptions &&
                (selected === null || selected === void 0 ? void 0 : selected.map(selectedItem => (_jsx(SelectedItemComponent, { label: selectedItem.label, onDelete: deleteSelection(selectedItem), onClick: onValueClick ? () => onValueClick(selectedItem.value) : undefined, blockHtml: blockHtml }, `selectedItem${selectedItem.value}`)))), _jsx("input", { ref: inputElement, type: 'text', className: 'multiple', "data-test-id": `${dataTestId ? dataTestId + '_' : ''}just-select__multiple_search`, onFocus: onFocus, autoFocus: autoFocus, onChange: onChange, onKeyDown: backSpaceDeleteSelected, value: searchValue || '', onSubmit: e => e.stopPropagation(), "aria-invalid": invalid, disabled: disabled, placeholder: !hideMultipleSelectedOptions && selected.length === 0 ? inputPlaceholder : '' }), !hideMultipleSelectedOptions && selected.length > 0 && closeable && (_jsx("div", { className: 'just-select-selected-item-cleanup', onClick: cleanupSelected, style: styleIconCleanUp, children: _jsx(Icon, { name: 'faTimesCircle', size: size }) })), iconArrowShow && (_jsx("div", { className: 'just-select-container-open', "data-test-id": 'just-select-container-open-icon', onClick: handleClickArrow, children: _jsx(Icon, { name: dropdownIconName, size: size }) }))] }));
};
