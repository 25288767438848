export const settingsLocalization = {
  'Settings:Groups:CreateGroup:Count:1': {
    eng: 'agent',
    ru: 'оператор',
    pt: 'agente',
  },
  'Settings:Groups:CreateGroup:Count:2': {
    eng: 'agents',
    ru: 'оператора',
    pt: 'agentes',
  },
  'Settings:Groups:CreateGroup:Count:3': {
    eng: 'agents',
    ru: 'операторов',
    pt: 'agentes',
  },
  'Plural:Prompters:Zero': {
    eng: 'No prompters',
    ru: 'Нет суфлеров',
    pt: 'Nenhum prompter',
  },
  'Plural:Prompters:One': {
    eng: '1 prompter',
    ru: '1 суфлер',
    pt: '1 prompter',
  },
  'Plural:Prompters:Two': {
    eng: '2 prompters',
    ru: '2 суфлера',
    pt: '2 prompters',
  },
  'Plural:Prompters:Few': {
    eng: '$[1] prompters',
    ru: '$[1] суфлера',
    pt: '$[1] prompters',
  },
  'Plural:Prompters:Many': {
    eng: '$[1] prompters',
    ru: '$[1] суфлеров',
    pt: '$[1] prompters',
  },
  'Settings:Groups:Create:Group:Selected:Users': {
    eng: 'Selected:',
    ru: 'Выбрано:',
    pt: 'Selecionado:',
  },
  'Settings:Groups:Create:Group:Selected:Supervisors': {
    eng: 'Selected:',
    ru: 'Выбрано:',
    pt: 'Selecionado:',
  },
  'Settings:Tab:groups': {
    eng: 'Groups',
    ru: 'Группы',
    pt: 'Grupos',
  },
  'Settings:Params token label': {
    eng: 'Access token',
    ru: 'Токен',
    pt: 'Token de acesso',
  },
  'Settings:Tab:operators': {
    eng: 'Users',
    ru: 'Пользователи',
    pt: 'Usuários',
  },
  'Settings:Tab:params': {
    eng: 'Preferences',
    ru: 'Параметры',
    pt: 'Preferências',
  },
  'Settings:Tab:prompters': {
    eng: 'Prompters',
    ru: 'Суфлеры',
    pt: 'Prompters',
  },
  'Settings:Tab:frames': {
    eng: 'Frames',
    ru: 'Фреймы',
  },
  'Settings:main header': {
    eng: 'Settings',
    ru: 'Настройки',
    pt: 'Configurações',
  },
  'Settings:Frames:Description': {
    eng: 'Frames are HTML elements that you can embed in the operator interface.',
    ru: 'Фреймы — это HTML-элементы, которые вы можете встроить в интерфейс операторов.',
  },
  'Settings:Frames:Input:UniqueName:Error': {
    eng: 'A frame with this name already exists',
    ru: 'Фрейм с таким названием уже существует',
  },
  'Settings:Frames:Input:TextLong:Error': {
    eng: 'The name is too long',
    ru: 'Название слишком длинное',
  },
  'Settings:Frames:Description:Link': {
    eng: 'How to configure and add a frame',
    ru: 'Как настроить и добавить фрейм',
  },
  'Settings:Frames:Add:New:Frame': {
    eng: 'Add frame',
    ru: 'Добавить фрейм',
  },
  'Settings:Frames:Input:Name': {
    eng: 'Name',
    ru: 'Название',
  },
  'Settings:Frames:Input:HTMLCode': {
    eng: 'HTML code',
    ru: 'HTML-код',
  },
  'Settings:Frames:Title': {
    eng: 'Frames',
    ru: 'Фреймы',
  },
  'Settings:Groups:Frames:Description': {
    eng: 'Select frames to embed them in the operator interface.',
    ru: 'Выберите фреймы, чтобы встроить их в интерфейс операторов.',
  },
  'Settings:Groups: description': {
    eng: 'Use agent groups to aggregate agents according to their tasks. Configure transferring dialogs to different groups in different bots or use several groups in one bot.',
    ru: 'С помощью групп вы можете объединить операторов, например, по их специализации. Настройте перевод на разные группы операторов в разных ботах или используйте несколько групп в одном.',
    pt: 'Use grupos de agentes para agregar agentes de acordo com suas tarefas. Configure a transferência de caixas de diálogo para diferentes grupos em diferentes bots ou use vários grupos em um bot.',
  },
  'Settings:Groups: create group button': {
    eng: 'Add group',
    ru: 'Добавить группу',
    pt: 'Adicionar grupo',
  },
  'Settings:Group create modal title': {
    eng: 'New group',
    ru: 'Новая группа',
    pt: 'Novo grupo',
  },
  'Settings:Group group name label': {
    eng: 'Name',
    ru: 'Название',
    pt: 'Nome',
  },
  'Settings:Group:MainOperator': {
    eng: 'Default agent',
    ru: 'Оператор по умолчанию',
    pt: 'Default agent',
  },
  'Settings:Group:NoMainOperator': {
    eng: 'No default agent assigned',
    ru: 'Оператор по умолчанию не назначен',
    pt: 'No default agent assigned',
  },
  'Settings:Group group name placeholder': {
    eng: 'Agent group',
    ru: 'Группа операторов',
    pt: 'Grupo de agentes',
  },
  'Settings:Group group name description': {
    eng: 'The group name is displayed to a customer.',
    ru: 'Название группы отображается клиенту.',
    pt: 'O nome do grupo é exibido para um cliente.',
  },
  'Settings:Group:MainOperator:Description': {
    eng: 'Accepts dialogs in this group',
    ru: 'Принимает диалоги в этой группе',
    pt: 'Accepts dialogs in this group',
  },
  'Settings:Group:Users:Label': {
    eng: 'Users',
    ru: 'Пользователи',
    pt: 'Usuários',
  },
  'Settings:Operators:Operator:Search:Placeholder': {
    eng: 'Search by name and email',
    ru: 'Поиск по имени и email',
    pt: 'Pesquisar por nomes e e-mail',
  },
  'Settings:Group:Operators:Founded': {
    eng: 'Users found:',
    ru: 'Найдено пользователей:',
    pt: 'Usuários encontrados:',
  },
  'Settings:Group:Supervisors:Search:Placeholder': {
    eng: 'Search by name and email',
    ru: 'Поиск по имени и email',
    pt: 'Pesquisar por nomes e e-mail',
  },
  'Settings:Group:Supervisors:Founded': {
    eng: 'Found:',
    ru: 'Найдено:',
    pt: 'Encontrados:',
  },
  'Settings:Operator:Operator:Role:SUPERVISOR': {
    eng: 'Supervisor',
    ru: 'Супервизор',
    pt: 'Supervisor',
  },
  'Settings:Operator:Operator:Role:ADMIN': {
    eng: 'Administrator',
    ru: 'Администратор',
    pt: 'Administrador',
  },
  'Settings:Operator:Operator:Role:AGENT': {
    eng: 'Agent',
    ru: 'Оператор',
    pt: 'Agente',
  },
  'Settings:Group:Delete:Modal:Title': {
    eng: 'Delete group?',
    ru: 'Удалить группу?',
    pt: 'Excluir grupo?',
  },
  'Settings:Group:Delete:Modal:Info:Top': {
    ru: 'Группа {groupName} будет удалена.',
    eng: 'The {groupName} group will be deleted.',
  },
  'Settings:Group:Delete:Modal:Info:Middle': {
    ru: 'Все диалоги в работе и в очереди будут завершены.',
    eng: 'All the chats in progress and in the queue will be closed.',
  },
  'Settings:Group:Delete:Modal:Info:Bottom': {
    ru: 'Если эта группа используется по умолчанию в проекте, бот перестанет переводить диалоги на операторов, а в сценарии будет возникать ошибка.',
    eng: 'If a project uses this group by default, the bot will stop transferring chats to agents and an error will occur in the script.',
  },
  'Settings:Operators: description': {
    eng: 'Invite users, select their roles, and assign them to the appropriate groups so that they are able to join chats.',
    ru: 'Чтобы пользователи могли подключаться к диалогам, пригласите их, выберите роли и добавьте в нужные группы.',
    pt: 'Convide usuários, selecione suas funções e atribua-os aos grupos apropriados para que possam participar de chats.',
  },
  'Settings:Operators: operator search full placeholder': {
    eng: 'Search by name, email, and groups',
    ru: 'Поиск по имени, email и группам',
    pt: 'Pesquise por nome, e-mail e grupos',
  },
  'Settings:Operators: create operator button': {
    eng: 'Invite user',
    ru: 'Пригласить пользователя',
    pt: 'Convidar usuário',
  },
  'Settings:Operator create modal title': {
    eng: 'New user',
    ru: 'Новый пользователь',
    pt: 'Novo usuário',
  },
  'Settings:Prompters:Description': {
    eng: 'Prompters are bots that provide agents with answers to customer questions. Add prompter bots here and then connect them in the group settings.',
    ru: 'Суфлеры — это боты, которые подсказывают оператору ответы на вопросы клиента. Добавляйте сюда ботов-суфлеров, а затем подключайте их в настройках группы.',
    pt: 'Prompters são bots que fornecem aos agentes respostas às perguntas dos clientes. Adicione bots de prompter aqui e conecte-os nas configurações do grupo.',
  },
  'Settings:Prompters:Description:Link': {
    eng: '<a href="#{config.aimychat.helpUrl}/settings/prompters">How to add and set up prompters</a>',
    ru: '<a href="#{config.aimychat.helpUrl}/settings/prompters">Как подключить и настроить суфлера</a>',
    pt: '<a href="#{config.aimychat.helpUrl}/settings/prompters">Como adicionar e conectar prompters</a>',
  },
  'Settings:Prompters:Add': {
    eng: 'Add prompter',
    ru: 'Добавить суфлера',
    pt: 'Adicionar prompter',
  },
  'Settings:Prompters:Name:Placeholder': {
    eng: 'Name',
    ru: 'Название',
    pt: 'Nome',
  },
  'Settings:Prompters:Name:Error:AlreadyExists': {
    eng: 'This name is used for another prompter.',
    ru: 'Это название используется для другого суфлера.',
    pt: 'Esse nome é usado para outro prompter.',
  },
  'Settings:Prompters:Url:Error:AlreadyExists': {
    eng: 'A prompter with this webhook is already added.',
    ru: 'Суфлер с этим вебхуком уже добавлен.',
    pt: 'Um prompter com este webhook já foi adicionado.',
  },
  'Settings:Prompters:Url:Error:NotFound': {
    eng: 'There is no such webhook.',
    ru: 'Несуществующий вебхук.',
    pt: 'Não existe tal webhook.',
  },
  'Settings:Prompters:Url:Placeholder': {
    eng: 'Prompter API webhook',
    ru: 'Вебхук Prompter API',
    pt: 'Webhook da Prompter API',
  },
  'Settings:Prompters:Modal:Remove:Description': {
    eng: 'Are you sure you want to delete the <b>$[1]</b> prompter? If it is connected to groups, it will no longer prompt agents.',
    ru: 'Вы уверены, что хотите удалить суфлера <b>$[1]</b>? Если он подключен к группам, он больше не сможет подсказывать операторам.',
    pt: 'Tem certeza de que deseja excluir o prompter <b>$[1]</b>? Se estiver conectado a grupos, não fará mais sugestões.',
  },
  'Settings:Frames:Modal:Remove:Description': {
    eng: 'The <b>{name}</b> frame will be deleted. The operators won’t be able to use it anymore',
    ru: 'Фрейм <b>{name}</b> будет удален. Операторы больше не смогут его использовать',
  },
  'Settings:Prompters:Modal:Remove:Title': {
    eng: 'Delete prompter',
    ru: 'Удалить суфлера',
    pt: 'Excluir prompter',
  },
  'Settings:Frames:Modal:Remove:Title': {
    eng: 'Delete frame?',
    ru: 'Удалить фрейм?',
  },
  'Settings:Frames:Modal:Edit:Title': {
    eng: 'Edit frame',
    ru: 'Редактировать фрейм',
  },
  'Settings:Prompters:Modal:Edit:Title': {
    eng: 'Edit prompter',
    ru: 'Редактировать суфлера',
    pt: 'Editar prompter',
  },
  'Settings:Prompters:Modal:Tabs:Main': {
    eng: 'General settings',
    ru: 'Основное',
    pt: 'Configurações adicionais',
  },
  'Settings:Prompters:Modal:Tabs:Users': {
    eng: 'Users',
    ru: 'Пользователи',
    pt: 'Usuários',
  },
  'Settings:Prompters:Modal:Tabs:Prompters': {
    eng: 'Prompters',
    ru: 'Суфлеры',
    pt: 'Prompters',
  },
  'Settings:Prompters:Modal:Tabs:Frames': {
    eng: 'Frames',
    ru: 'Фреймы',
  },
  'Settings:Prompters:Modal:Tabs:Supervisors': {
    eng: 'Supervisors',
    ru: 'Супервизоры',
    pt: 'Supervisores',
  },
  'Settings:Prompters:Context': {
    eng: 'Context prompter',
    ru: 'Контекстный суфлер',
    pt: 'Prompter de contexto',
  },
  'Settings:Prompters:Helpers': {
    eng: 'Prompter assistants',
    ru: 'Суфлеры-помощники',
    pt: 'Assistentes de prompter',
  },
  'Settings:Prompters:Helpers:Description': {
    eng: 'Agents can consult with prompter assistants during a dialog with a customer.',
    ru: 'Операторы могут обращаться к суфлерам-помощникам во время диалога с клиентом.',
    pt: 'Os agentes podem consultar os assistentes do prompter durante um diálogo com um cliente.',
  },
  'Settings:Prompters:NotUsed': {
    eng: 'Not used',
    ru: 'Не используется',
    pt: 'Não usado',
  },
  'Settings:Prompters:ContextPrompter': {
    eng: 'Context prompter',
    ru: 'Контекстный суфлер',
    pt: 'Prompter de contexto',
  },
  'Settings:Prompters:ContextPrompter:Description': {
    eng: 'Context prompter picks suitable answers to a customer and displays them in the chat window. You can use only one context prompter.',
    ru: 'Подбирает подходящие ответы клиенту и показывает их в окне диалога. Контекстный суфлер может быть только один.',
    pt: 'O prompter de contexto escolhe respostas adequadas para um cliente e as exibe na janela de chat. Você pode usar apenas um prompter de contexto.',
  },
  'Setings:Prompters:NotConnected': {
    eng: 'Not connected to any agent group',
    ru: 'Не подключен к группам операторов',
    pt: 'Não conectado a nenhum grupo de agentes',
  },
  'Settings:Prompters:MaxResponseCount': {
    eng: 'Answer display limit',
    ru: 'Максимум ответов',
    pt: 'Limite de exibição de resposta',
  },
  'Settings:Prompters:MaxResponseCount:Description': {
    eng: 'This number of answer options can be displayed in the chat window.',
    ru: 'Столько вариантов ответа могут отображаться в окне диалога.',
    pt: 'Este número de opções de resposta pode ser exibido na janela de chat.',
  },
  'Settings:Group edit modal title': {
    eng: 'Edit group',
    ru: 'Редактировать группу',
    pt: 'Editar grupo',
  },
  'Settings:Operator:Create:Description': {
    eng: 'Before adding a user to $[1], <a class="no-underline" target="_blank" href="$[2]">invite them to your account</a>. Then select a role and enter the user login here.',
    ru: 'Прежде чем добавить пользователя в $[1], <a class="no-underline" target="_blank" href="$[2]">пригласите его в свой аккаунт</a>. Затем здесь выберите роль и введите логин пользователя.',
    pt: 'Antes de adicionar um usuário a $[1], <a class="no-underline" target="_blank" href="$[2]">convide-o para sua conta</a>. Em seguida, selecione uma função e digite o login do usuário aqui.',
  },
  'Settings:Operator:Create:Description:Link': {
    eng: '<b><a class="no-underline" target="_blank" href="#{config.aimychat.helpUrl}/settings/user-management#invite-user">How to add a user</a></b>',
    ru: '<b><a class="no-underline" target="_blank" href="#{config.aimychat.helpUrl}/settings/user-management#invite-userasd">Как добавить пользователя</a></b>',
    pt: '<b><a class="no-underline" target="_blank" href="#{config.aimychat.helpUrl}/settings/user-management#invite-user">Como adicionar um usuário</a></b>',
  },
  'Settings:OperatorCreate:Link': {
    eng: 'More about project groups',
    ru: 'Подробнее о группах проектов',
    pt: 'Mais sobre grupos de projetos',
  },
  'Settings:Operator:Operator:Role:label': {
    eng: 'Role',
    ru: 'Роль',
    pt: 'Função',
  },
  'Settings:Operator delete modal title': {
    eng: 'Delete user',
    ru: 'Удалить пользователя',
    pt: 'Excluir usuário',
  },
  'Settings:Operator delete modal info': {
    eng: 'Are you sure you want to delete this user: $[1]? All their active chats will be forwarded to the group queue, and all closed chats will be available in the “History” tab.',
    ru: 'Вы уверены, что хотите удалить пользователя $[1]? Все его активные диалоги будут переданы в очередь группы, а завершенные диалоги можно будет найти во вкладке «Архив».',
    pt: 'Tem certeza de que deseja excluir este usuário: $[1]? Todos os chats ativos serão encaminhados para a fila do grupo e todos os chats fechados estarão disponíveis na guia “Histórico”.',
  },
  'Settings:Operator operator login label': {
    eng: 'Login',
    ru: 'Логин',
    pt: 'Login',
  },
  'Settings:Operator send request button': {
    eng: 'Add',
    ru: 'Добавить',
    pt: 'Adicionar',
  },
  'Settings:Operator operator group label': {
    eng: 'Agent groups',
    ru: 'Группы операторов',
    pt: 'Grupos de agentes',
  },
  'Settings:Operator:MainOperatorForGroup': {
    eng: 'Default agent for the group',
    ru: 'Оператор по умолчанию для группы',
    pt: 'Default agent for the group',
  },
  'Settings:Operator:MainOperatorForGroup:Description': {
    eng: 'Accepts dialogs in this group',
    ru: 'Принимает диалоги в этой группе',
    pt: 'Accepts dialogs in this group',
  },
  'Settings:Operator:MainOperatorForGroup:NoGroup': {
    eng: 'No group selected',
    ru: 'Группа не выбрана',
    pt: 'No group selected',
  },
  'Settings:Operator edit modal title': {
    eng: 'Edit user',
    ru: 'Редактировать пользователя',
    pt: 'Editar usuário',
  },
  'Settings:Operator edit button': {
    eng: 'Edit group',
    ru: 'Редактировать группу',
    pt: 'Editar grupo',
  },
  'Settings:Params token description': {
    eng: 'Use this token to connect the $[1] customer engagement platform in $[2] and $[3].',
    ru: 'Используйте этот токен, чтобы подключить операторский канал $[1] в $[2] и $[3].',
    pt: 'Use este token para conectar a plataforma de engajamento do cliente $[1] em $[2] e $[3].',
  },
  'Settings:Params token regenerate': {
    eng: 'Regenerate',
    ru: 'Сгенерировать новый',
    pt: 'Regenerado',
  },
  'Settings:Params autoset label': {
    eng: 'Automatically assign chats',
    ru: 'Автоназначение диалогов',
    pt: 'Atribuir chats automaticamente',
  },
  'Settings:Params:OperatorInvitation:Label': {
    eng: 'Invite other agents to chats',
    ru: 'Добавление других операторов в чат',
  },
  'Settings:Params autoset description': {
    eng: 'With this option enabled, chats will be automatically assigned to agents according to the maximum number of chats. Agents will not be able to join chats from the group queue by themselves.',
    ru: 'При включенной настройке диалоги автоматически назначаются на операторов с учетом максимального количества диалогов. Операторы не смогут самостоятельно брать в работу диалоги из очереди группы.',
    pt: 'Com esta opção habilitada, os chats serão atribuídos automaticamente aos agentes de acordo com o número máximo de chats. Os agentes não poderão entrar em chats da fila do grupo sozinhos.',
  },
  'Settings:Params autoset button enabled': {
    eng: 'On',
    ru: 'Вкл',
    pt: 'Ativado',
  },
  'Settings:Params autoset button disable': {
    eng: 'Off',
    ru: 'Откл',
    pt: 'Desligado',
  },
  'Settings:Params max label': {
    eng: 'Maximum number of chats per agent',
    ru: 'Максимальное количество диалогов на оператора',
    pt: 'Número máximo de chats por agente',
  },
  'Settings:Params max description': {
    eng: 'Number of active dialogs that an agent might have at one time.',
    ru: 'Количество диалогов, которые могут быть одновременно в работе у оператора.',
    pt: 'Número de diálogos ativos que um agente pode ter ao mesmo tempo.',
  },
  'Settings:Params order label': {
    eng: 'Sort chats in queue',
    ru: 'Сортировка диалогов в очереди',
    pt: 'Classificar chats na fila',
  },
  'Settings:Params order description': {
    eng: 'This setting defines the chat display order in group queues.',
    ru: 'Определяет порядок отображения диалогов в очередях групп.',
    pt: 'Esta configuração define a ordem de exibição do chat nas filas de grupo.',
  },
  'Settings:Params order ASC': {
    eng: 'Oldest chats first',
    ru: 'Сначала старые диалоги',
    pt: 'Chats mais antigos primeiro',
  },
  'Settings:Params order DESC': {
    eng: 'Newest chats first',
    ru: 'Сначала новые диалоги',
    pt: 'Chats mais recentes primeiro',
  },
  'Settings:Params time label': {
    eng: 'Agent response time',
    ru: 'Время ответа оператора',
    pt: 'Tempo de resposta do agente',
  },
  'Settings:Params time description': {
    eng: 'If an agent does not respond to a customer within the specified period of time, the chat will be forwarded to the group queue.',
    ru: 'Если оператор не отвечает клиенту в течение указанного времени, диалог будет переведен на другого оператора группы.',
    pt: 'Se um agente não responder a um cliente dentro do período de tempo especificado, o chat será encaminhado para a fila do grupo.',
  },
  'Settings:Params:Notification:Label': {
    eng: 'Sound notifications',
    ru: 'Звуковые уведомления',
    pt: 'Notificações de som',
  },
  'Settings:Users:Limits': {
    eng: 'Users: ',
    ru: 'Пользователи: ',
    pt: 'Usuários: ',
  },
  'Settings:Groups:Limits': {
    eng: 'Groups: ',
    ru: 'Группы: ',
    pt: 'Grupos: ',
  },
  'Settings:Limits': {
    eng: '$[1] from $[2]',
    ru: '$[1] из $[2]',
    pt: '$[1] de $[2]',
  },
  'Settings:Users:Upgrade': {
    eng: '<a target="_blank" href="#{config.aimychat.helpUrl}/limits#increase-limits">How to increase the limit on the number of users?</a>',
    ru: '<a target="_blank" href="#{config.aimychat.helpUrl}/limits#increase-limits">Как увеличить лимит на количество пользователей?</a>',
    pt: '<a target="_blank" href="#{config.aimychat.helpUrl}/limits#increase-limits">Como aumentar o limite do número de usuários?</a>',
  },
  'Settings:Groups:Upgrade': {
    eng: '<a target="_blank" href="#{config.aimychat.helpUrl}/limits#increase-limits">How to increase the limit on the number of groups?</a>',
    ru: '<a target="_blank" href="#{config.aimychat.helpUrl}/limits#increase-limits">Как увеличить лимит на количество групп?</a>',
    pt: '<a target="_blank" href="#{config.aimychat.helpUrl}/limits#increase-limits">Como aumentar o limite do número de grupos?</a>',
  },
  'Settings:Params:Minutes': {
    eng: 'min',
    ru: 'мин',
    pt: 'min',
  },
  'Settings:Params:Seconds': {
    eng: 'sec',
    ru: 'сек',
    pt: 's',
  },
  'SoundNotifications option ALL': {
    eng: 'New messages and chats',
    ru: 'Новые сообщения и диалоги',
    pt: 'Novas mensagens e chats',
  },
  'SoundNotifications option NOTHING': {
    eng: 'Disabled',
    ru: 'Отключены',
    pt: 'Desabilitado',
  },
  'SoundNotifications option ONLY_NEW_MESSAGES': {
    eng: 'New messages',
    ru: 'Новые сообщения',
    pt: 'Novas mensagens',
  },
  'SoundNotifications option ONLY_NEW_CHATS': {
    eng: 'New chats',
    ru: 'Новые диалоги',
    pt: 'Novos chats',
  },
  'Settings:Users:OneAdminRequired': {
    eng: 'The group should contain at least one administrator.',
    ru: 'В группе должен быть хотя бы один администратор.',
    pt: 'O grupo deve conter pelo menos um administrador.',
  },
  'Settings:Prompters:SendStart:Label': {
    ru: 'Отправлять суфлеру команду /start в начале диалога',
    eng: 'Send “/start” command to prompter when chat starts',
    pt: 'Envie o comando /start para o prompter quando um chat começar',
  },
};
