import React, { useCallback } from 'react';
import { Modal, SafelyHtmlText, useToggle } from '@just-ai/just-ui';
import { t } from '../../../../../localization';
import GroupService from '@just-ai/aimychat-shared/dist/service/GroupService';
import { OperatorGroupFullDto } from '@just-ai/aimychat-shared/dist/api/client/aimychat';
import { useOperatorDataContext } from '../../../../OperatorPlace/OperatorDataContext';

const DeleteGroupModal = ({
  onClose,
  deleteGroup,
  accountId,
  groupToDelete,
}: {
  onClose: () => void;
  deleteGroup: (id: number) => void;
  accountId: number;
  groupToDelete: OperatorGroupFullDto;
}) => {
  const { active, created } = useOperatorDataContext();
  const [inDeleting, setInDeleting, finishDeleting] = useToggle(false);
  const countOfActiveChats = [...active, ...created].filter(chat => chat.operatorGroup.id === groupToDelete.id).length;

  const onConfirm = useCallback(async () => {
    try {
      const service = new GroupService(accountId);
      setInDeleting();
      await service.deleteGroup(groupToDelete.id);
      deleteGroup(groupToDelete.id);
      finishDeleting();
      onClose();
    } catch (e) {
      console.error(e);
    }
  }, [setInDeleting, deleteGroup, finishDeleting, onClose, groupToDelete?.id, accountId]);

  return (
    <Modal
      onClickCloseIcon={onClose}
      onCancelClick={onClose}
      buttonCancelColor='secondary'
      buttonSubmitColor='danger'
      buttonSubmitTestId='Groups.deleteModal.submit'
      buttonCancelTestId='Groups.deleteModal.cancel'
      onActionClick={onConfirm}
      title={t('Settings:Group:Delete:Modal:Title')}
      buttonCancelText={t('Cancel')}
      buttonSubmitText={t('Delete')}
      className='mobile-modal'
      disableActionButtonAutoFocus
      isOpen
      inProgress={inDeleting}
    >
      <SafelyHtmlText
        wrapperTag='p'
        html={t('Settings:Group:Delete:Modal:Info:Top', {
          groupName: `<b data-test-id="Group.DeleteModal.groupName">${groupToDelete.name}</b>`,
        })}
      />

      {!!countOfActiveChats && <p>{t('Settings:Group:Delete:Modal:Info:Middle')}</p>}
      <p>{t('Settings:Group:Delete:Modal:Info:Bottom')}</p>
    </Modal>
  );
};

export default DeleteGroupModal;
